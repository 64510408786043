import '../styles/home.css';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Map, { Marker, Source, Layer } from 'react-map-gl';
import { db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import SectionsHead from '../components/common/SectionsHead';


const SingleStoreMap = () => {
  const { storeId } = useParams();
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [userLocation, setUserLocation] = useState(false);
  const [selectedStore, setSelectedStore] = useState(null);
  const [route, setRoute] = useState(null);

  const apiKM = "pk.eyJ1IjoiZ29kc3dpbGwtbmF0aGFuIiwiYSI6ImNtMHU3OXk0dDAzZnYya3M1OW1ybHI4M24ifQ.dQGIlS-j2osnaxsRwdFrUA";

  // Get user location and set it
  const location_find = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
        setUserLocation(true);
      }, () => {
        // alert('Unable to retrieve location.');
      });
    }
  };

  // Fetch store data by ID
  const fetchStoreById = async (id) => {
    const storeRef = doc(db, 'stores', id);
    const storeSnapshot = await getDoc(storeRef);

    if (storeSnapshot.exists()) {
      const storeData = storeSnapshot.data();
      setSelectedStore({ id: storeId, ...storeData });
      // Fetch route after store and location are set
      if (latitude && longitude) {
        fetchRoute(storeData.lat, storeData.lng);
      }
    }
  };

  // Fetch route using Mapbox API
  const fetchRoute = async (storeLat, storeLng) => {
    if (latitude !== null && longitude !== null) {
      const url = `https://api.mapbox.com/directions/v5/mapbox/walking/${longitude},${latitude};${storeLng},${storeLat}?geometries=geojson&access_token=${apiKM}`;
      const response = await fetch(url);
      const data = await response.json();
      setRoute(data.routes[0]?.geometry);
    }
  };

  // Trigger location finding and store fetching
  useEffect(() => {
    location_find();
  }, []);

  useEffect(() => {
    if (userLocation) {
      fetchStoreById(storeId);
    }
  }, [userLocation]);

  return (
    <>
    <br /><br /> <br />
       
      
      {userLocation && selectedStore ? (
        
<>
        <Map 
          mapboxAccessToken={apiKM}
          initialViewState={{
            longitude: longitude,
            latitude: latitude,
            zoom: 12
          }}
          style={{ height: 300 }}
          mapStyle="mapbox://styles/mapbox/streets-v9"
        >
          <Marker longitude={longitude} latitude={latitude} anchor="bottom">
            <img src="../location.png" width={30} height={30} alt="user location"/>
          </Marker>

          <Marker
            key={selectedStore.id}
            longitude={selectedStore.lng}
            latitude={selectedStore.lat}
            anchor="bottom"
          >
            <img src={selectedStore.storeIconURL} width={40} height={40} alt="store location" style={{ borderRadius: '50%', border: '3px solid red' }} />
          </Marker>

          {route && (
            <Source id="route" type="geojson" data={route}>
              <Layer
                id="route"
                type="line"
                layout={{ "line-join": "round", "line-cap": "round" }}
                paint={{ "line-color": "red", "line-width": 5 }}
              />
            </Source>
          )}
        </Map>

        <br /> <br/>

<h5 className="products_info" style={{textAlign: "center", fontSize: 22, fontWeight: "bold"}}>
 {`${selectedStore.storeName}`}
</h5>
<br/> <br/>

<h5 className="products_info" style={{textAlign: "center", fontSize: 20,}}>

{` ${selectedStore.description}`}
</h5>

<h5 className="products_info" style={{textAlign: "center", fontSize: 19,}}>
Address: {` ${selectedStore.address}`}
</h5>

</>
      ) : (
        <p style={{ color: 'white', textAlign: 'center' }}>Loading map...</p>
      )}
<br /> <br/>

    </>
  );
};

export default SingleStoreMap;




// import '../styles/home.css';  // Add a CSS file for custom styles


// import React, { useContext, useState, useEffect } from 'react';
// import { Link, useParams } from 'react-router-dom';

// import { BsCartX } from 'react-icons/bs';
// import { calculateTotal, displayMoney } from '../helpers/utils';
// import useDocTitle from '../hooks/useDocTitle';
// import cartContext from '../contexts/cart/cartContext';
// import CartItem from '../components/cart/CartItem';
// import EmptyView from '../components/common/EmptyView';
// // import { QrReader } from 'react-qr-reader';
// import { Scanner } from '@yudiel/react-qr-scanner';

// import '../styles/popup.css'; // Include your CSS for styling

// import { auth, db } from '../firebase'; // Firebase configuration

// import { collection, query, orderBy, startAt, endAt, getDocs, addDoc, onSnapshot, getFirestore} from 'firebase/firestore';
// import Map, { Marker, Popup, Source, Layer } from 'react-map-gl'; 

// const geofire = require("geofire-common");

// const Map = () => {
//   useDocTitle('Map');

//   const apiBaseUrl = 'http://localhost:2000'; // Change this to your API URL

//   const { cartItems } = useContext(cartContext);
//   const [pickup, handlePickup] = useState(false);
//   const [isOpen, setIsOpen] = useState(false);
//   const [scannedData, setScannedData] = useState(null);

//   const [discount, setDiscount] = useState(0);

//   const cartQuantity = cartItems.length;

//   // total original price
//   const cartTotal = cartItems.map(item => item.originalPrice * item.quantity);
//   const calculateCartTotal = calculateTotal(cartTotal);
//   const displayCartTotal = displayMoney(calculateCartTotal);

//   // total discount
 
//   const cartDiscount = cartItems.map(
//     // item => { (item.originalPrice - item.finalPrice) * item.quantity}
//     // item =>  (( item.finalPrice - (20 / 100) * item.finalPrice )) * item.quantity
//     item =>  (item.finalPrice - discount ) * item.quantity
//   );
//   const calculateCartDiscount = calculateTotal(cartDiscount);
//   const displayCartDiscount = displayMoney(calculateCartDiscount);

//   // final total amount
//   const totalAmount = calculateCartTotal - calculateCartDiscount;
//   const displayTotalAmount = displayMoney(totalAmount);

//   const openPopup = () => setIsOpen(true);
//   const closePopup = () => setIsOpen(false);

//   const [popup_Order, setPopup_Order] = useState("");
//   const [popup_Payer, setPopup_Payer] = useState("");

  

//   const Pickup_start = () => handlePickup(true);

//     //   async function Checkout() {

//     //    cartItems.map((item) => {

//     //     try {
//     //       addDoc(collection(db, 'orders'), item);
//     //       alert('Checkout Successful');
//     //     } catch (error) {
//     //       console.error('Error adding document: ', error);
//     //     }

      
          
//     //   })
        

       
//     // }

//     async function Checkout() {
//       try {

//         // db.collection('orders').add({
//         //   store_id,
//         //   buyerName: buyerName,
//         //   buyerImage: "https://cartpost.shop/Store.png", // Buyer's image URL from Firebase Storage
//         //   // status: status,
//         //   status: "pending", // "pending", 'done'
//         //   products: selectedProducts, // Store full product details here
//         //   timestamp: firebase.firestore.FieldValue.serverTimestamp(),
//         //     orderNo: "",
//         //     payer: user,
//         //     expiry: firebase.firestore.FieldValue.serverTimestamp() + 43_200_000, // 86_400_000 (24 hours to milis)
//         //     picked: false, // "picked up" 
//         // })


//         await Promise.all(cartItems.map(item => addDoc(collection(db, 'orders'), item)));
//         alert('Checkout Successful');


//         // For Demo
//         let idToken;
//         auth.currentUser.getIdToken().then(function(idToken_) {
//            // Send token to your backend via HTTPS
//            // ...
//            idToken = idToken_
//            let store_id = "rpkpzBW9NFZ1zELcOwmbVUkEfRF2";
//            let order_id = "rpkpzBW9NFZ1zELcOwmbVUkEfRF2";

//            fetch(`${apiBaseUrl}/paymentdone`, {
//              method: 'POST',
//              headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${idToken}` }, // Replace with your token
//             //  body: JSON.stringify({qrcode: data}),
//             order_id, store_id
//          }) 
//          // For Demo


//           });


//       } catch (error) {
//         console.error('Error adding document: ', error);
//       }
//     }
    

//   const handleScan = async (data) => {
//     if (data) {
//       setScannedData(data);
//       try {
//         let idToken;

//        auth.currentUser.getIdToken().then(function(idToken_) {
//           // Send token to your backend via HTTPS
//           // ...
//           idToken = idToken_
          
//           fetch(`${apiBaseUrl}/verify-order`, {
//             method: 'POST',
//             headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${idToken}` }, // Replace with your token
//             body: JSON.stringify({qrcode: data}),
//         }).then((op)=>{
//           let response = op.json();
         
//           setPopup_Order(response.orderNo);
//           setPopup_Payer(response.payer)

//           openPopup();
//           // alert(`Order verified successfully, id: ${response.orderNo}`);

//         });


//         }).catch(function(error) {
//           // Handle error
//           alert(`Order verification failed, error: ${error}`);
//         });
        
        

        
//       } catch (error) {
//         console.error('Error adding document: ', error);
//       }

//     }
  
//   };

//   const handleError = (err) => {
//     console.error(err);
//   };

//   useEffect(()=>{

//       setDiscount(400); // in Amount

//   }, [])


//   // 

//   const [latitude, setLatitude] = useState(0);
//   const [longitude, setLongitude] = useState(0);
//   const [userLocation, setUserLocation] = useState(false);
//   const [nearbyStores, setNearbyStores] = useState([]);
//   const [selectedStore, setSelectedStore] = useState("All");  // Default to "All"
//   const [selectedStoreName, setSelectedStoreName] = useState("All");  // Default to "All"
//   const [route, setRoute] = useState(null);  // Route coordinates


//   // Function to get user location
//   async function location_find() {
//       if (navigator.geolocation) {
//           navigator.geolocation.getCurrentPosition((position) => {
//               setLatitude(position.coords.latitude);
//               setLongitude(position.coords.longitude);
//               setUserLocation(true);
//               fetchNearbyStores(position.coords.latitude, position.coords.longitude);
//           }, (error) => {
//               alert('Unable to retrieve location.');
//           });
//       }
//   }

//   // New fetchNearbyStores function using geohash bounds for querying
//   const fetchNearbyStores = async (lat, long) => {
//       const center = [lat, long]; // User's current location
//       // const radiusInM = 18 * 1000; // 18 km radius
//       const radiusInM = 1500 * 1000; // 15 km radius
      
//       const bounds = geofire.geohashQueryBounds(center, radiusInM);
//       const promises = [];

//       for (const b of bounds) {
//           const q = query(
//               collection(db, 'stores'),
//               orderBy('geohash'), 
//               startAt(b[0]),
//               endAt(b[1])
//           );
//           promises.push(getDocs(q));
//       }

//       const snapshots = await Promise.all(promises);
//       const matchingDocs = [];

//       for (const snap of snapshots) {
//           for (const doc of snap.docs) {
//               const storeData = doc.data();
//               const storeLat = storeData.lat;
//               const storeLng = storeData.lng;

//               const distanceInKm = geofire.distanceBetween([storeLat, storeLng], center);
//               const distanceInM = distanceInKm * 1000;
//               if (distanceInM <= radiusInM) {
//                   matchingDocs.push({ id: doc.id, ...storeData });
//               }
//           }
//       }

//       setNearbyStores([{ id: "All", name: "All" }, ...matchingDocs]); // Add "All" as a default option
//   };

  
//   const apiKM = "pk.eyJ1IjoiZ29kc3dpbGwtbmF0aGFuIiwiYSI6ImNtMHU3OXk0dDAzZnYya3M1OW1ybHI4M24ifQ.dQGIlS-j2osnaxsRwdFrUA";

//   const fetchRoute = async (storeLat, storeLng) => {
//       const url = `https://api.mapbox.com/directions/v5/mapbox/walking/${longitude},${latitude};${storeLng},${storeLat}?geometries=geojson&access_token=${apiKM}`;
//       const response = await fetch(url);
//       const data = await response.json();
//       setRoute(data.routes[0]?.geometry);
//   };

//   useEffect(() => {
//       if (selectedStore !== "All") {
//           const store = nearbyStores.find(s => s.id === selectedStore);
//           if (store) fetchRoute(store.lat, store.lng);
//       } else {
//           setRoute(null);  // Clear route if "All" is selected
//       }
//   }, [selectedStore]);

//   useEffect(() => {
//       location_find();
//   }, []);


//   // 
 
//   return (
//     <>

  
  
//     {cartQuantity != 0 && (
//      userLocation ? (
//                     <>  <br /><br /><br/>
//                         <Map 
                      
//                       mapboxAccessToken="pk.eyJ1IjoiZ29kc3dpbGwtbmF0aGFuIiwiYSI6ImNtMHU3OXk0dDAzZnYya3M1OW1ybHI4M24ifQ.dQGIlS-j2osnaxsRwdFrUA" 
//                            initialViewState={{
//                                 longitude: longitude,
//                                 latitude: latitude,
//                                 zoom: 13
//                             }}
//                             // style={{ height: 200 }}
//                             style={{ height: 250 }}
//                             mapStyle="mapbox://styles/mapbox/streets-v9"
//                         >
//                             <Marker longitude={longitude} latitude={latitude} anchor="bottom">
//                                 <img src="./location.png" width={30} height={30} alt="user location"/>
//                             </Marker>

//                             {nearbyStores
//                                 .filter(store => store.id !== "All")
//                                 .map((store) => (
//                                     <Marker
//                                         key={store.id}
//                                         longitude={store.lng}
//                                         latitude={store.lat}
//                                         anchor="bottom"
//                                     >
//                                         {/* <img src="./Store.png" width={30} height={30} alt="store location"/> */}
//                                         {/* <img src={store.storeIconURL} width={30} height={30} alt="store location"/> */}

//                                         <img src={store.storeIconURL} width={40} height={40} alt="store location" style={{ borderRadius: '50%', border: '3px solid red' }} />
                                        
//                                     </Marker>
//                                 ))}

// {route && (
//                             <Source id="route" type="geojson" data={route}>
//                                 <Layer
//                                     id="route"
//                                     type="line"
//                                     source="route"
//                                     layout={{ "line-join": "round", "line-cap": "round" }}
//                                     paint={{
//                                         // "line-color": "#3b9ddd",
//                                         "line-color": "red",
//                                         "line-width": 5
//                                     }}
//                                 />
//                             </Source>
//                         )}

//                         </Map>

//                         <div className="store-slider-group">
//                             {nearbyStores.map((store) => (
//                                 <div
//                                     key={store.id}
//                                     className={`store-card ${selectedStore === store.id ? 'active' : ''}`}
//                                     onClick={() => {setSelectedStore(store.id);
//                                         setSelectedStoreName(store.name);}
                                        
//                                     }
//                                 >
//                                     <h4>{store.name}</h4>
//                                 </div>
//                             ))}
//                         </div>
//                     </>
//                 ) : (
//                     <p style={{ color: 'white', textAlign: 'center' }}>Loading map...</p>
//                 )

// )}
//       <section id="cart" className="section">
        

           


//         <div className="container">
//           {cartQuantity === 0 ? (
//             <EmptyView
//               icon={<BsCartX />}
//               msg="Your Shopping bag is Empty"
//               // link="/all-products"
//               link="/"
//               btnText="Start Shopping"
//             />
//           ) : (
//             <div className="wrapper cart_wrapper">
//               <div className="cart_left_col">
//                 {cartItems.map((item) => (
//                   <CartItem key={item.id} {...item} />
//                 ))}
               
//               </div>

//               <div className="cart_right_col">

//               {isOpen && (
//         <div className="popup-overlay">
//           <div className="popup-content">
//             <div className="popup-header">
//               <img
//                 src="./done icon.png"
//                 alt="done icon"
//                 style={{ width: '90%', height: 'auto', marginTop: '10px' }}
//               />
//               <button className="popup-close" onClick={closePopup}>✖</button>
//             </div>
//             <div className="popup-body">
//               <h1>Order: {popup_Order}</h1>
//               <p>Verification complete!</p>
//               <p>Customer: {popup_Payer}</p>
//             </div>
//           </div>
//         </div>
//       )}


//                 <div className="order_summary">
//                   <h3>
//                     Order Summary &nbsp;( {cartQuantity}{' '}
//                     {cartQuantity > 1 ? 'items' : 'item'} )
//                   </h3>
//                   <div className="order_summary_details">
//                     <div className="price">
//                       <span>Original Price</span>
//                       <b>{displayCartTotal}</b>
//                     </div>
//                     {discount != 0 &&(
//                        <div className="discount">
//                       <span>Discount</span>
//                       {/* <b>- {displayCartDiscount}</b> */}
//                       <b>- {displayTotalAmount}</b>
                      
//                     </div>
//                     ) }
                   
                    
//                     <div className="separator"></div>
//                     <div className="total_price">
//                       <b><small>Total Price</small></b>
//                       <b>{displayCartDiscount}</b>
//                     </div>
//                   </div>
//                   <button type="button" className="btn checkout_btn" onClick={Checkout}>Checkout</button>
                                                     
//                   {/* <br />
//                   <hr />
//                   <br /> */}
//                   <div className="separator"></div>

//                   <button
//                     type="button"
//                     className="btn checkout_btn"
//                     onClick={Pickup_start}
//                   >
//                     Pick up / Verify
//                   </button>
//                   {pickup ? (
// <>
// <Scanner onScan={(result) => {
//   if (result) { 
// handleScan(result);
//   } 
// }}

// style={{ width: '300px' }}

// />


// <br />
// <br />
// <br />
// </>
             
//                   ) : (
//                     <></>
//                   )}

// {/* 
// {isOpen && (
//         <div className="popup-overlay">
//           <div className="popup-content">
//             <div className="popup-header">
//               <img
//                 src="./done icon.png"
//                 alt="done icon"
//                 style={{ width: '80%', height: 'auto', marginTop: '10px' }}
//               />
//               <button className="popup-close" onClick={closePopup}>✖</button>
//             </div>
//             <div className="popup-body">
//               <h1>Order: 235</h1>
//               <p>Verification complete!</p>
//               <p>Customer: Pep</p>
//             </div>
//           </div>
//         </div>
//       )} */}
//                 </div>
//               </div>
//             </div>
//           )}
//         </div>
//       </section>
//     </>
//   );
// };

// export default Map;
