import React, { useContext, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import commonContext from '../../contexts/common/commonContext';
import useForm from '../../hooks/useForm';
import useOutsideClose from '../../hooks/useOutsideClose';
import useScrollDisable from '../../hooks/useScrollDisable';
import { auth, db } from '../../firebase'; // Firebase auth instance
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import {doc, serverTimestamp, collection, addDoc, setDoc, getDoc, updateDoc, onSnapshot, namedQuery } from "firebase/firestore"; // Firebase storage functions

const AccountForm = () => {
    const { isFormOpen, toggleForm } = useContext(commonContext);
    const { inputValues, handleInputValues } = useForm();
    const formRef = useRef();
    useOutsideClose(formRef, () => toggleForm(false));
    useScrollDisable(isFormOpen);

    const [isSignupVisible, setIsSignupVisible] = useState(false);
    const [error, setError] = useState(null);
    const googleProvider = new GoogleAuthProvider();

    // Toggle between login and signup forms
    const handleIsSignupVisible = () => setIsSignupVisible(prevState => !prevState);


    async function Init_new_user() {
        if (auth.currentUser) {
            let name = '';
    
            // Check the user's sign-in method and set name based on provider data
            if (auth.currentUser.providerData[0]?.providerId === 'phone') {
                // Phone authentication: no displayName typically available
                name = auth.currentUser.phoneNumber || 'Phone User';
            } else if (auth.currentUser.providerData[0]?.providerId === 'password') {
                // Email-password authentication
                name = auth.currentUser.displayName || 'Email User';
            } else if (auth.currentUser.providerData[0]?.providerId === 'google.com') {
                // Google authentication
                name = auth.currentUser.displayName || 'Google User';
            }
    
            // Save the new user data to Firestore
            await setDoc(doc(db, 'users', auth.currentUser.uid), {
                joined: new Date().toISOString(),
                timestamp: serverTimestamp(),
                name: name,
                email: auth.currentUser.email || null,
                phoneNumber: auth.currentUser.phoneNumber || null,
                provider: auth.currentUser.providerData[0]?.providerId || 'unknown',
            });
    
            console.log("User initialized successfully!");
        } else {
            console.error("No authenticated user found.");
        }
    }
    
    // Email and password authentication
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        const { mail, password } = inputValues;

        if (isSignupVisible) {
            try {
                await createUserWithEmailAndPassword(auth, mail, password);
                Init_new_user();

                toggleForm(false);
            } catch (error) {
                setError(error.message);
            }
        } else {
            try {
                await signInWithEmailAndPassword(auth, mail, password);
                toggleForm(false);
            } catch (error) {
                setError(error.message);
            }
        }
    };

    // Google authentication
    const handleGoogleLogin = async () => {
        try {
            await signInWithPopup(auth, googleProvider);

            toggleForm(false);
        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <>
            {isFormOpen && (
                <div className="backdrop">
                    <div className="modal_centered">
                        <form id="account_form" ref={formRef} onSubmit={handleFormSubmit}>
                            <div className="form_head">
                                <h2>{isSignupVisible ? 'Signup' : 'Welcome back'}</h2>
                                <p>
                                    {isSignupVisible ? 'Already have an account?' : 'New to Cartpost?'}
                                    &nbsp;&nbsp;
                                    <button type="button" onClick={handleIsSignupVisible}>
                                        {isSignupVisible ? 'Login' : 'Create an account'}
                                    </button>
                                </p>
                            </div>

                            <div className="form_body">
                                {isSignupVisible && (
                                    <div className="input_box">
                                        <input
                                            type="text"
                                            name="username"
                                            className="input_field"
                                            value={inputValues.username || ''}
                                            onChange={handleInputValues}
                                            required
                                        />
                                        <label className="input_label">Username</label>
                                    </div>
                                )}

                                <div className="input_box">
                                    <input
                                        type="email"
                                        name="mail"
                                        className="input_field"
                                        value={inputValues.mail || ''}
                                        onChange={handleInputValues}
                                        required
                                    />
                                    <label className="input_label">Email</label>
                                </div>

                                <div className="input_box">
                                    <input
                                        type="password"
                                        name="password"
                                        className="input_field"
                                        value={inputValues.password || ''}
                                        onChange={handleInputValues}
                                        required
                                    />
                                    <label className="input_label">Password</label>
                                </div>

                                {isSignupVisible && (
                                    <div className="input_box">
                                        <input
                                            type="password"
                                            name="conf_password"
                                            className="input_field"
                                            value={inputValues.conf_password || ''}
                                            onChange={handleInputValues}
                                            required
                                        />
                                        <label className="input_label">Confirm Password</label>
                                    </div>
                                )}

                                <button type="submit" className="btn login_btn">
                                    {isSignupVisible ? 'Signup' : 'Login'}
                                </button>

                                {error && <p className="error_message">{error}</p>}
                            </div>

                            <div className="form_foot">
                                <p>or login with</p>
                                <div className="login_options" style={{ alignContent: 'center', alignSelf: 'center', textAlign: 'center' }}>
                                    <button className="btnkl" onClick={handleGoogleLogin}>
                                        Google
                                    </button>

                                    {/* For phone authentication, a setup with reCAPTCHA is required */}
                                    <button className="btnkl">
                                        Phone No
                                    </button>
                                </div>
                            </div>

                            <div className="close_btn" title="Close" onClick={() => toggleForm(false)}>
                                &times;
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </>
    );
};

export default AccountForm;


// import React, { useContext, useRef, useState } from 'react';
// import { Link } from 'react-router-dom';
// import commonContext from '../../contexts/common/commonContext';
// import useForm from '../../hooks/useForm';
// import useOutsideClose from '../../hooks/useOutsideClose';
// import useScrollDisable from '../../hooks/useScrollDisable';

// const AccountForm = () => {

//     const { isFormOpen, toggleForm } = useContext(commonContext);
//     const { inputValues, handleInputValues, handleFormSubmit } = useForm();

//     const formRef = useRef();

//     useOutsideClose(formRef, () => {
//         toggleForm(false);
//     });

//     useScrollDisable(isFormOpen);

//     const [isSignupVisible, setIsSignupVisible] = useState(false);


//     // Signup-form visibility toggling
//     const handleIsSignupVisible = () => {
//         setIsSignupVisible(prevState => !prevState);
//     };


//     return (
//         <>
//             {
//                 isFormOpen && (
//                     <div className="backdrop">
//                         <div className="modal_centered">
//                             <form id="account_form" ref={formRef} onSubmit={handleFormSubmit}>

//                                 {/*===== Form-Header =====*/}
//                                 <div className="form_head">
//                                     {/* <h2>{isSignupVisible ? 'Signup' : 'Welcome back Login'}</h2> */}
//                                     <h2>{isSignupVisible ? 'Signup' : 'Welcome back'}</h2>

//                                     <p>
//                                         {isSignupVisible ? 'Already have an account ?' : 'New to Cartpost ?'}
//                                         &nbsp;&nbsp;
//                                         <button type="button" onClick={handleIsSignupVisible}>
//                                             {isSignupVisible ? 'Login' : 'Create an account'}
//                                         </button>
//                                     </p>
//                                 </div>

//                                 {/*===== Form-Body =====*/}
//                                 <div className="form_body">
//                                     {
//                                         isSignupVisible && (
//                                             <div className="input_box">
//                                                 <input
//                                                     type="text"
//                                                     name="username"
//                                                     className="input_field"
//                                                     value={inputValues.username || ''}
//                                                     onChange={handleInputValues}
//                                                     required
//                                                 />
//                                                 <label className="input_label">Username</label>
//                                             </div>
//                                         )
//                                     }

//                                     <div className="input_box">
//                                         <input
//                                             type="email"
//                                             name="mail"
//                                             className="input_field"
//                                             value={inputValues.mail || ''}
//                                             onChange={handleInputValues}
//                                             required
//                                         />
//                                         <label className="input_label">Email</label>
//                                     </div>

//                                     <div className="input_box">
//                                         <input
//                                             type="password"
//                                             name="password"
//                                             className="input_field"
//                                             value={inputValues.password || ''}
//                                             onChange={handleInputValues}
//                                             required
//                                         />
//                                         <label className="input_label">Password</label>
//                                     </div>

//                                     {
//                                         isSignupVisible && (
//                                             <div className="input_box">
//                                                 <input
//                                                     type="password"
//                                                     name="conf_password"
//                                                     className="input_field"
//                                                     value={inputValues.conf_password || ''}
//                                                     onChange={handleInputValues}
//                                                     required
//                                                 />
//                                                 <label className="input_label">Confirm Password</label>
//                                             </div>
//                                         )
//                                     }

//                                     <button
//                                         type="submit"
//                                         className="btn login_btn"
//                                     >
//                                         {isSignupVisible ? 'Signup' : 'Login'}
//                                     </button>

//                                 </div>

//                                 {/*===== Form-Footer =====*/}
//                                 <div className="form_foot">
//                                     <p>or login with</p>
//                                     <div className="login_options" style={{alignContent: "center",
//                                         alignSelf: "center",
//                                         textAlign: "center"
//                                     }}>
                                      


//                                         <button
//                                         className="btnkl"
//                                     >
//                                         {/* {isSignupVisible ? 'Google' : 'Google'} */}
//                                        Google
//                                     </button>

//                                     <button
//                                         className="btnkl"
//                                     >
//                                         {/* {isSignupVisible ? 'Phone No' : 'Phone No'} */}
//                                       Phone&nbsp;No
//                                     </button>

//                                     </div>

//                                     {/* <Link> <p onClick={handleIsSignupVisible}>Signup</p> </Link> */}
//                                 {/* <p onClick={handleIsSignupVisible}>Signup</p> */}
                                 
//                                 </div>

//                                 {/*===== Form-Close-Btn =====*/}
//                                 <div
//                                     className="close_btn"
//                                     title="Close"
//                                     onClick={() => toggleForm(false)}
//                                 >
//                                     &times;
//                                 </div>

//                             </form>
//                         </div>
//                     </div>
//                 )
//             }
//         </>
//     );
// };

// export default AccountForm;