import '../styles/home.css';  // Add a CSS file for custom styles

import React, { useState, useEffect } from 'react';
import Map, { Marker, Popup, Source, Layer } from 'react-map-gl'; 
import { Link } from 'react-router-dom';
import HeroSlider from '../components/sliders/HeroSlider';
import TopProducts from '../components/product/TopProducts';
import Services from '../components/common/Services';
import FeaturedSlider from '../components/sliders/FeaturedSlider';
import SectionsHead from '../components/common/SectionsHead';
import { collection, query, orderBy, startAt, endAt, getDocs } from 'firebase/firestore';
import { auth, db } from '../firebase';  // Assuming you export Firestore instance as `db` from firebase.js

import FilterBar from '../components/filters/FilterBar';


const geofire = require("geofire-common");

const Home = () => {
    const [latitude, setLatitude] = useState(0);
    const [longitude, setLongitude] = useState(0);
    const [userLocation, setUserLocation] = useState(false);
    const [nearbyStores, setNearbyStores] = useState([]);
    const [selectedStore, setSelectedStore] = useState("All");  // Default to "All"
    const [selectedStoreName, setSelectedStoreName] = useState("All");  // Default to "All"
    const [route, setRoute] = useState(null);  // Route coordinates


    // Function to get user location
    async function location_find() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                setLatitude(position.coords.latitude);
                setLongitude(position.coords.longitude);
                setUserLocation(true);
                fetchNearbyStores(position.coords.latitude, position.coords.longitude);
            }, (error) => {
                // alert('Unable to retrieve location.');
            });
        }
    }

    // New fetchNearbyStores function using geohash bounds for querying
    const fetchNearbyStores = async (lat, long) => {
        const center = [lat, long]; // User's current location
        // const radiusInM = 18 * 1000; // 18 km radius
        const radiusInM = 1500 * 1000; // 15 km radius
        
        const bounds = geofire.geohashQueryBounds(center, radiusInM);
        const promises = [];

        for (const b of bounds) {
            const q = query(
                collection(db, 'stores'),
                orderBy('geohash'), 
                startAt(b[0]),
                endAt(b[1])
            );
            promises.push(getDocs(q));
        }

        const snapshots = await Promise.all(promises);
        const matchingDocs = [];

        for (const snap of snapshots) {
            for (const doc of snap.docs) {
                const storeData = doc.data();
                const storeLat = storeData.lat;
                const storeLng = storeData.lng;

                const distanceInKm = geofire.distanceBetween([storeLat, storeLng], center);
                const distanceInM = distanceInKm * 1000;
                if (distanceInM <= radiusInM) {
                    matchingDocs.push({ id: doc.id, ...storeData });
                }
            }
        }

        setNearbyStores([{ id: "All", name: "All" }, ...matchingDocs]); // Add "All" as a default option
    };

    
    const apiKM = "pk.eyJ1IjoiZ29kc3dpbGwtbmF0aGFuIiwiYSI6ImNtMHU3OXk0dDAzZnYya3M1OW1ybHI4M24ifQ.dQGIlS-j2osnaxsRwdFrUA";

    const fetchRoute = async (storeLat, storeLng) => {
        const url = `https://api.mapbox.com/directions/v5/mapbox/walking/${longitude},${latitude};${storeLng},${storeLat}?geometries=geojson&access_token=${apiKM}`;
        const response = await fetch(url);
        const data = await response.json();
        setRoute(data.routes[0]?.geometry);
    };

    useEffect(() => {
        if (selectedStore !== "All") {
            const store = nearbyStores.find(s => s.id === selectedStore);
            if (store) fetchRoute(store.lat, store.lng);
        } else {
            setRoute(null);  // Clear route if "All" is selected
        }
    }, [selectedStore]);

    useEffect(() => {
        location_find();
    }, []);

    return ( <>
     
        <main>
             
        <section id="all_products" style={{paddingTop: 60}} className="section">
        <FilterBar />

            <section className="container">
                <br/>
                <br/>
                <br/>
                {userLocation ? (
                    <>
                        <Map 
                      
                      mapboxAccessToken="pk.eyJ1IjoiZ29kc3dpbGwtbmF0aGFuIiwiYSI6ImNtMHU3OXk0dDAzZnYya3M1OW1ybHI4M24ifQ.dQGIlS-j2osnaxsRwdFrUA" 
                           initialViewState={{
                                longitude: longitude,
                                latitude: latitude,
                                zoom: 13
                            }}
                            // style={{ height: 200 }}
                            style={{ height: 250 }}
                            mapStyle="mapbox://styles/mapbox/streets-v9"
                        >
                            <Marker longitude={longitude} latitude={latitude} anchor="bottom">
                                <img src="./location.png" width={30} height={30} alt="user location"/>
                            </Marker>

                            {nearbyStores
                                .filter(store => store.id !== "All")
                                .map((store) => (
                                    <Marker
                                        key={store.id}
                                        longitude={store.lng}
                                        latitude={store.lat}
                                        anchor="bottom"
                                    >
                                        {/* <img src="./Store.png" width={30} height={30} alt="store location"/> */}
                                        {/* <img src={store.storeIconURL} width={30} height={30} alt="store location"/> */}

                                        <img src={store.storeIconURL} width={40} height={40} alt="store location" style={{ borderRadius: '50%', border: '3px solid red' }} />
                                        
                                    </Marker>
                                ))}

{route && (
                            <Source id="route" type="geojson" data={route}>
                                <Layer
                                    id="route"
                                    type="line"
                                    source="route"
                                    layout={{ "line-join": "round", "line-cap": "round" }}
                                    paint={{
                                        // "line-color": "#3b9ddd",
                                        "line-color": "red",
                                        "line-width": 5
                                    }}
                                />
                            </Source>
                        )}

                        </Map>

                        <div className="store-slider-group">
                            {nearbyStores.map((store) => (
                                <div
                                    key={store.id}
                                    className={`store-card ${selectedStore === store.id ? 'active' : ''}`}
                                    onClick={() => {setSelectedStore(store.id);
                                        setSelectedStoreName(store.name);}
                                        
                                    }
                                >
                                    <h4>{store.name}</h4>
                                </div>
                            ))}
                        </div>
                    </>
                ) : (
                    <p style={{ color: 'white', textAlign: 'center' }}>Loading map...</p>
                )}


                 <div className="container">
                <br />
                <br />
                {userLocation ? (
                    <>
                        <SectionsHead heading={selectedStore !== "All" ? `Products in ${selectedStoreName}` : "Top Products"} />
                        <TopProducts 
                            lat={latitude}
                            lng={longitude}
                            nearbyStores={nearbyStores}
                            selectedStore={selectedStore}
                        />
                    </>
                ) : (
                    <p style={{ color: 'white', textAlign: 'center' }}>Loading Products...</p>
                )}
            </div>
                <br />
                <br />
                <br />
                <br />


            </section>

           
       
                </section>
        </main>

      
             
           
     
                 {/* <section className="container">
                     <br/>
                     <br/>
                     <br/>
                     {userLocation ? (
                         <>
                             <Map 
                           
                           mapboxAccessToken="pk.eyJ1IjoiZ29kc3dpbGwtbmF0aGFuIiwiYSI6ImNtMHU3OXk0dDAzZnYya3M1OW1ybHI4M24ifQ.dQGIlS-j2osnaxsRwdFrUA" 
                                initialViewState={{
                                     longitude: longitude,
                                     latitude: latitude,
                                     zoom: 13
                                 }}
                                 // style={{ height: 200 }}
                                 style={{ height: 250 }}
                                 mapStyle="mapbox://styles/mapbox/streets-v9"
                             >
                                 <Marker longitude={longitude} latitude={latitude} anchor="bottom">
                                     <img src="./location.png" width={30} height={30} alt="user location"/>
                                 </Marker>
     
                                 {nearbyStores
                                     .filter(store => store.id !== "All")
                                     .map((store) => (
                                         <Marker
                                             key={store.id}
                                             longitude={store.lng}
                                             latitude={store.lat}
                                             anchor="bottom"
                                         >
                                        
                                             <img src={store.storeIconURL} width={40} height={40} alt="store location" style={{ borderRadius: '50%', border: '3px solid red' }} />
                                             
                                         </Marker>
                                     ))}
     
     {route && (
                                 <Source id="route" type="geojson" data={route}>
                                     <Layer
                                         id="route"
                                         type="line"
                                         source="route"
                                         layout={{ "line-join": "round", "line-cap": "round" }}
                                         paint={{
                                             // "line-color": "#3b9ddd",
                                             "line-color": "red",
                                             "line-width": 5
                                         }}
                                     />
                                 </Source>
                             )}
     
                             </Map>
     
                             <div className="store-slider-group">
                                 {nearbyStores.map((store) => (
                                     <div
                                         key={store.id}
                                         className={`store-card ${selectedStore === store.id ? 'active' : ''}`}
                                         onClick={() => {setSelectedStore(store.id);
                                             setSelectedStoreName(store.name);}
                                             
                                         }
                                     >
                                         <h4>{store.name}</h4>
                                     </div>
                                 ))}
                             </div>
                         </>
                     ) : (
                         <p style={{ color: 'white', textAlign: 'center' }}>Loading map...</p>
                     )}
                 </section>
     
                 <div className="container">
                     <br />
                     <br />
                     {userLocation ? (
                         <>
                             <SectionsHead heading={selectedStore !== "All" ? `Products in ${selectedStoreName}` : "Top Products"} />
                             <TopProducts 
                                 lat={latitude}
                                 lng={longitude}
                                 nearbyStores={nearbyStores}
                                 selectedStore={selectedStore}
                             />
                         </>
                     ) : (
                         <p style={{ color: 'white', textAlign: 'center' }}>Loading Products...</p>
                     )}
                 </div>
                     <br />
                     <br />
                     <br />
                     <br />
             */}
           
    </>);
};

export default Home;


