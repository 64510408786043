import '../styles/home.css';  // Add a CSS file for custom styles


import React, { useContext, useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import { BsCartX } from 'react-icons/bs';
import { calculateTotal, displayMoney } from '../helpers/utils';
import useDocTitle from '../hooks/useDocTitle';
import cartContext from '../contexts/cart/cartContext';
import CartItem from '../components/cart/CartItem';
import EmptyView from '../components/common/EmptyView';
// import { QrReader } from 'react-qr-reader';
import { Scanner } from '@yudiel/react-qr-scanner';

import '../styles/popup.css'; // Include your CSS for styling

import { auth, db } from '../firebase'; // Firebase configuration

import { collection, query, orderBy, startAt, endAt, getDocs, serverTimestamp, addDoc, updateDoc, doc, onSnapshot, getFirestore} from 'firebase/firestore';
import Map, { Marker, Popup, Source, Layer } from 'react-map-gl'; 

const geofire = require("geofire-common");

const Cart = () => {
  useDocTitle('Cart');

  const apiBaseUrl = 'http://localhost:2000'; // Change this to your API URL

  const { cartItems } = useContext(cartContext);
  
  const [isOpen, setIsOpen] = useState(false);
const [isOpen2, setIsOpen2] = useState(false);
const [pickup, setPickup] = useState(false);

const handleButtonClick = () => {
  console.log("Initial State - isOpen:", isOpen, "isOpen2:", isOpen2, "pickup:", pickup);

  setIsOpen((prev) => !prev);  // Toggle isOpen state
  setIsOpen2((prev) => !prev);  // Toggle isOpen2 state based on your need
  setPickup((prev) => !prev);   // Toggle pickup state based on your need

  console.log("Updated State - isOpen:", isOpen, "isOpen2:", isOpen2, "pickup:", pickup);
};


  const [scannedData, setScannedData] = useState(null);

  const [discount, setDiscount] = useState(0);

  const cartQuantity = cartItems.length;


  // total original price
  const cartTotal = cartItems.map(item => item.originalPrice * item.quantity);
  const calculateCartTotal = calculateTotal(cartTotal);
  const displayCartTotal = displayMoney(calculateCartTotal);

  // total discount
 
  const cartDiscount = cartItems.map(
    // item => { (item.originalPrice - item.finalPrice) * item.quantity}
    // item =>  (( item.finalPrice - (20 / 100) * item.finalPrice )) * item.quantity
    item =>  (item.finalPrice - discount ) * item.quantity
  );
  const calculateCartDiscount = calculateTotal(cartDiscount);
  const displayCartDiscount = displayMoney(calculateCartDiscount);

  // final total amount
  const totalAmount = calculateCartTotal - calculateCartDiscount;
  const displayTotalAmount = displayMoney(totalAmount);

  const openPopup = () => setIsOpen(true);
  const closePopup = () => setIsOpen(false);

  const openPopup2 = async () => setIsOpen2(true);
  const closePopup2 = () => setIsOpen2(false);

  const [popup_Order, setPopup_Order] = useState("");
  const [popup_Payer, setPopup_Payer] = useState("");

  

  // const Pickup_start = () => handlePickup(true);
  const Pickup_start = async () => { 
    //  handlePickup();
     openPopup();
    await handleScan("FDFA48E1")
    
    };
  

    
    async function Checkout() { 
      try {
        if (calculateCartDiscount < 500) {
          alert("Cannot pay below N500");
          return;
        }
    
        // Step 1: Group products by store
        const ordersByStore = cartItems.reduce((acc, item) => {
          const storeId = item.storeID; // Assuming each item has a storeId
          const image = item.images[0]; // Assuming each item has an image
    
          // Check if the storeId and image are valid
          if (!storeId || !image) {
            console.error('Invalid item data:', item);
            return acc; // Skip invalid items
          }
          
    
          if (!acc[storeId]) {
            acc[storeId] = {

              store_id: "",
              products: [],
              total: 0,
              image, // Get the image from the first item

            };
          }
          acc[storeId].products.push(item);
          acc[storeId].store_id += item.storeID;
          
          console.log(acc[storeId].store_id);
          console.log(item.storeID);
          console.log(item);
          acc[storeId].image += item.heroImage;
          acc[storeId].total += item.finalPrice * item.quantity; // Calculate total per store
          return acc;
        }, {});
    
        // Step 2: Create order documents for each store
        let lopp = [];
        await Promise.all(
          Object.values(ordersByStore).map(async (order) => {
            if (order.products.length === 0) {
              return; // Skip empty orders
            }

              
            // Add order document to Firestore
   await addDoc(collection(db, 'orders'), {
              store_id: order.store_id,
              orderNo: 0,
              payer: auth.currentUser.uid,
              picked: false, // "picked up" 
        
              products: order.products,
              total: order.total,
              image: order.image, // Get the image from the first product
              expiry: new Date().getUTCMilliseconds + 86400000,

              
              buyerName: "buyerName",
              buyerImage: "https://cartpost.shop/Store.png", // Buyer's image URL from Firebase Storage
              status: "pending", // "pending", 'done'
              timestamp: serverTimestamp(),
              // Include other fields like buyer information if needed
            }).then((op)=>{
              console.log(op)
              let main_main = op.id;
              updateDoc(doc(db, 'orders', op.id),{
                id: op.id
              });
              lopp.push(op.id);
            })


          })
        );

       let ticket = await addDoc(collection(db, 'tickets'), {
          uid: auth.currentUser.uid,
          links: lopp,
          timestamp: serverTimestamp(),
          expiry: new Date().getUTCDate(),
          paid: false,
         })
    
        // alert('Checkout Successful');


    
        // // Optional: Send payment confirmation
        const idToken = await auth.currentUser.getIdToken();
        const ticket_id = ticket.id; // Example order ID
    
        await fetch(`${apiBaseUrl}/rpkpzBW9NFZ1zELcOwmbVUkEfRF2`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${idToken}` },
          // body: JSON.stringify({ order_id, store_id }),
          body: JSON.stringify({  ticket: ticket_id }),          
        });

        
        alert('Checkout Successful');
    


      } catch (error) {
        console.error('Error adding document: ', error);
      }
    }
    

  
//     const handleScan = async (data) => {
//   openPopup();

//        if (data) {
//       setScannedData(data);

//       // setIsOpen22 = true;

//       try {
//         let idToken;

//         console.log(pickup)
     
//        console.log(pickup)

//      await auth.currentUser.getIdToken().then(async function (idToken_) {
//           // Send token to your backend via HTTPS
//           // ...
//           idToken = idToken_
          
//           let op = await fetch(`${apiBaseUrl}/verify-order`, {
//             method: 'POST',
//             headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${idToken}` }, // Replace with your token
//             body: JSON.stringify({qrcode: data}),
      
//         })
//         // .then((op)=>{
         
//           console.log(op);
//           console.log(op.body) 
//           let response = JSON.parse(op);
//          console.log(`${response}`)
//           setPopup_Order(response.orderNo);
//           setPopup_Payer(response.payer)

//           // openPopup();
//           alert(`Order verified successfully, id: ${response.orderNo}`);

//         // });


//         }).catch(function(error) {
//           // Handle error
//           alert(`Order verification failed, error: ${error}`);
//         });
        
        

        
//       } catch (error) {
//         console.error('Error adding document: ', error);
//       }

// closePopup();

//     }
  
//   };


const handleScan = async (data) => {
  openPopup();
  setIsOpen2(false);

  if (data) {
    setScannedData(data);

    try {
      let idToken;

      console.log(pickup);

      await auth.currentUser.getIdToken().then(async (idToken_) => {
        idToken = idToken_;

        // Send the request to your backend
        const op = await fetch(`${apiBaseUrl}/verify-order`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${idToken}`
          },
          body: JSON.stringify({ qrcode: data }),
        });

        // Wait for the response to be parsed as JSON
        const response = await op.json();

        // Check if the request was successful
        if (op.ok && response.statusCode === 200) {
          setPopup_Order(response.body.orderNo);
          setPopup_Payer(response.body.payer);

          // alert(`Order verified successfully, ID: ${response.body.orderNo}`);
        } else {
          alert(`Order verification failed: ${response.error}`);
        }
      }).catch((error) => {
        closePopup();
        // Handle any token errors
        alert(`Error obtaining token: ${error}`);
      });

    } catch (error) {
      closePopup();
      console.error('Error verifying order: ', error);
      alert(`Error verifying order: ${error.message}`);
    }

    // closePopup();
    setIsOpen2(true);
  }
};



  const handleError = (err) => {
    console.error(err);
  };

  // useEffect(()=>{

  //     setDiscount(400); // in Amount

  // }, [])

  useEffect(() => {
    console.log("isOpen:", isOpen, "isOpen2:", isOpen2, "pickup:", pickup);
  }, [isOpen, isOpen2, pickup]);
  

  function calculateDiscountedPrice(amount) {
    if (amount >= 500 && amount < 3000) {
      return (amount * (3/100)) + amount;
    
  } else if (amount >= 3000 && amount < 4000) {
    return (amount + 60) + amount;
  }
    else if (amount >= 4000 && amount < 12000) {
      return (amount * (1.5/100)) + amount;
    } else if (amount >= 12000 && amount < 60000) {
      return amount + 200;
    } else if (amount >= 60_000) {
      return amount + 500;
    }  else {
      return amount;
    }
  }
  


 
  return (
    <>

 
      <section id="cart" className="section">
        
        




        <div className="container">
          {cartQuantity === 0 ? (
            <EmptyView
              icon={<BsCartX />}
              msg="Your Wishlist is Empty"
              // link="/all-products"
              link="/"
              btnText="Start Shopping"
            />
          ) : (
            <div className="wrapper cart_wrapper">
              <div className="cart_left_col">
                {cartItems.map((item) => (
                  <CartItem key={item.id} {...item} />
                ))}
               
              </div>

              <div className="cart_right_col">

        


                <div className="order_summary">
                  <h3>
                    Order Summary &nbsp;( {cartQuantity}{' '}
                    {cartQuantity > 1 ? 'items' : 'item'} )
                  </h3>
                  <div className="order_summary_details">
                    <div className="price">
                      <span>Original Price</span>
                      <b>{displayCartTotal}</b>
                      {/* <b>{(3 / 100) * displayCartTotal }</b> */}
                    </div>
                    {discount !== 0 &&(
                       <div className="discount">
                      <span>Discount</span>
                      {/* <b>- {displayCartDiscount}</b> */}
                      <b>- {displayTotalAmount}</b>
                      
                    </div>
                    ) }
                   
                    
                    <div className="separator"></div>
                    <div className="total_price">
                      <b><small>Total Price</small></b>
                      {/* <b>{displayCartDiscount}</b> */}
                      {/* <b>{calculateCartDiscount}</b> */}
                      
                      <b>{
 
  displayMoney(calculateDiscountedPrice(calculateCartDiscount))

       
                    }</b>
                    
                    </div>  <h5 className="products_info"> (Includes Service fees) </h5>
                  </div>
                  <button type="button" className="btn checkout_btn" onClick={Checkout}>Buy All</button>
                                                     
                  {/* <br />
                  <hr />
                  <br /> */}
                  <div className="separator"></div>

                  <button
                    type="button"
                    className="btn checkout_btn"
                    onClick={Pickup_start}
                  >
                  

                  { Pickup_start }
                    Pick up / Verify
                  </button>
{/* 
{isOpen && (
        <div className="popup-overlay">
          <div className="popup-content">
            <div className="popup-header">
              <img
                src="./done icon.png"
                alt="done icon"
                style={{ width: '80%', height: 'auto', marginTop: '10px' }}
              />
              <button className="popup-close" onClick={closePopup}>✖</button>
            </div>
            <div className="popup-body">
              <h1>Order: 235</h1>
              <p>Verification complete!</p>
              <p>Customer: Pep</p>
            </div>
          </div>
        </div>
      )} */}
                </div>
              </div>
            </div>
          )}
        </div>
      </section>


      {pickup ? (
<>
<Scanner onScan={(result) => {
  if (result) { 
handleScan(result);
  } 
}}

style={{ width: '300px' }}

/>
<br />
<br />
<br />
</>         ) : (
                    <></>
                  )}
      
{isOpen && (
        <div className="popup-overlay">
          <div className="popup-content">

              {!isOpen2 ? (<>
              
              <div className="popup-header">
           <img  src="./Animation-l.gif" style={{ width: "40%" }} alt="Loading.."/> 
              <button className="popup-close" onClick={closePopup}>✖</button>
           </div>


              </> ) : (<> 
               <div className="popup-header">
              <img src="./done-icon.webp" alt="Done" style={{ width: "40%" }} />
              <button className="popup-close" onClick={closePopup}>✖</button>
            </div>
            <div className="popup-body" style={{textAlign: "center"}}>
              <p style={{color: "black", fontWeight: "bold", fontSize: 20}}>Verification complete!</p>
              <h1 style={{color: "black", fontWeight: "bold", fontSize: 20}}>Order: {popup_Order}</h1>
              <p style={{color: "black", fontWeight: "bold", fontSize: 15}}>Customer: <br/> {popup_Payer}</p>
            </div>
               </>) }
           
      
          </div>
        </div>
      )}
      
    </>
  );
};

export default Cart;

