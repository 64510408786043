import React, { useContext } from 'react';
import { TbTrash } from 'react-icons/tb';
import { Link } from 'react-router-dom';
import { displayMoney } from '../../helpers/utils';
import cartContext from '../../contexts/cart/cartContext';
import QuantityBox from '../common/QuantityBox';


const CartItem = (props) => {

    const { storeID, storeName, id, images, title, info, finalPrice, originalPrice, quantity, qty, path } = props;

    const { removeItem } = useContext(cartContext);

    const newPrice = displayMoney(finalPrice);
    const oldPrice = displayMoney(originalPrice);


    return (
        <>
            <div className="cart_item">
                <figure className="cart_item_img">
                    <Link to={`${path}${id}`}>
                        <img src={images[0]} alt="product-img" />
                    </Link>
                </figure>
                <div className="cart_item_info">
                    <div className="cart_item_head">
                        <h4 className="cart_item_title">
                            <Link to={`/product-details/${id}`}>{title} {info}</Link>

                        

                        </h4>

                     
                        <div className="cart_item_del">
                            <span onClick={() => removeItem(id)}>
                                <TbTrash />
                            </span>
                            <div className="tooltip">Remove Item</div>
                        </div>
                        <br/>


                      
                    </div>
<br />
                    
<Link to={`/map/${storeID}`}>
<h5 className="products_info">
                        <img src='./location.png' style={{width: 15, height: 20}} />
                      
                        &nbsp; at {` ${storeName}`}
                        </h5>
</Link>

<br />
                    <h5 className="products_info">Stock units: &nbsp;
                        <b>{`${qty}`}</b>
                        </h5>


                    <h2 className="cart_item_price">
                        {newPrice} &nbsp;
                        {/* <small><del>{oldPrice}</del></small> */}
                    </h2>

                    <QuantityBox itemId={id} itemQuantity={quantity} quantity={qty} />
                </div>
            </div>
        </>
    );
};

export default CartItem;