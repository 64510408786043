import React from 'react';
import { Routes, Route } from 'react-router';
import useScrollRestore from '../hooks/useScrollRestore';
import AllProducts from '../pages/AllProducts';
import SingleStoreMap from "../pages/Map"

import Cart from '../pages/Cart';
import Home from '../pages/Home';
import ProductDetails from '../pages/ProductDetails';
import ErrorPage from '../pages/ErrorPage';
import Profile from "../pages/Profile"
import Bag from "../pages/Orders"

const RouterRoutes = () => {

    useScrollRestore();

    return (
        <>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/cart" element={<Cart />} />
                {/* <Route path="/order/:orderId" element={<Cart />} /> */}
                {/* <Route path="/order/:orderId" element={<AnOrder />} /> */}
                
                <Route path="/all-products" element={<AllProducts />} />
                <Route path="/product-details/:productId" element={<ProductDetails />} />
                <Route path="*" element={<ErrorPage />} />

                <Route path="/profile" element={<Profile />} />
                <Route path="/map/:storeId" element={<SingleStoreMap />} />
                <Route path="/bag" element={<Bag />} />
            </Routes>
        </>
    );
};

export default RouterRoutes;