import React, { useContext, useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import commonContext from '../../contexts/common/commonContext';
import { db } from '../../firebase';
import { collection, getDocs, query, where, orderBy, limit, startAt, endAt } from 'firebase/firestore';
import useOutsideClose from '../../hooks/useOutsideClose';
import useScrollDisable from '../../hooks/useScrollDisable';
const geofire = require("geofire-common");


const SearchBar = (props) => {
    const [latitude, setLatitude] = useState(0);
    const [longitude, setLongitude] = useState(0);
    const [userLocation, setUserLocation] = useState(false);
    const [nearbyStores, setNearbyStores] = useState([]);

    const [searchTerm, setSearchTerm] = useState("");
    const [isFocused, setIsFocused] = useState(false);

    const { isSearchOpen, toggleSearch, searchResults, setSearchResults } = useContext(commonContext);

    const searchRef = useRef();

    useEffect(() => {
        location_find();
    }, []);

    // Close SearchBar
    const closeSearch = () => {
        toggleSearch(false);
        setSearchResults([]);
    };

    useOutsideClose(searchRef, closeSearch);
    useScrollDisable(isSearchOpen);

    // Function to get user location
    async function location_find() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                setLatitude(position.coords.latitude);
                setLongitude(position.coords.longitude);
                setUserLocation(true);
                fetchNearbyStores(position.coords.latitude, position.coords.longitude);
            }, (error) => {
                // alert('Unable to retrieve location.');
            });
        }
    }

    // New fetchNearbyStores function using geohash bounds for querying
    const fetchNearbyStores = async (lat, long) => {
        const center = [lat, long];
        const radiusInM = 1500 * 1000; // 15 km radius
        
        const bounds = geofire.geohashQueryBounds(center, radiusInM);
        const promises = [];

        for (const b of bounds) {
            const q = query(
                collection(db, 'stores'),
                orderBy('geohash'), 
                startAt(b[0]),
                endAt(b[1])
            );
            promises.push(getDocs(q));
        }

        const snapshots = await Promise.all(promises);
        const matchingDocs = [];

        for (const snap of snapshots) {
            for (const doc of snap.docs) {
                const storeData = doc.data();
                const storeLat = storeData.lat;
                const storeLng = storeData.lng;

                const distanceInKm = geofire.distanceBetween([storeLat, storeLng], center);
                const distanceInM = distanceInKm * 1000;
                if (distanceInM <= radiusInM) {
                    matchingDocs.push({ id: doc.id, ...storeData });
                }
            }
        }

        setNearbyStores([...matchingDocs]);
    };

    // Handle Search
    // const handleSearching = async (e) => {
    //     const searchedTerm = e.target.value.toLowerCase().trim();
    //     // const searchedTerm = e.target.value.trim();

    //     if (searchedTerm === '' || nearbyStores.length === 0) {
    //         setSearchResults([]);
    //         return;
    //     }

    //     try {
    //         const productsRef = collection(db, 'products');
    //         const nearbyStoreIds = nearbyStores.map(store => store.id);

    //         const q = query(
    //             productsRef,
    //             where('storeID', 'in', nearbyStoreIds), // Filter products by nearby stores
    //             where('searchkey', '>=', searchedTerm),
    //             where('searchkey', '<=', searchedTerm + '\uf8ff'),
    //             limit(10)
    //         );

    //         const querySnapshot = await getDocs(q);
    //         const updatedSearchResults = querySnapshot.docs
    //             .map(doc => ({ id: doc.id, ...doc.data() }))
    //             // .filter(item => item.title.toLowerCase().includes(searchedTerm));
    //             .filter(item => item.searchkey.includes(searchedTerm));


    //         setSearchResults(updatedSearchResults);
    //     } catch (error) {
    //         console.error("Error fetching search results:", error);
    //         setSearchResults([]); // Reset results in case of error
    //     }
    // };
    const handleSearching = async (e) => {
        const searchedTerm = e.target.value.toLowerCase().trim();
        setSearchTerm(searchedTerm);
    
        if (searchedTerm === '' || nearbyStores.length === 0) {
            setSearchResults([]);
            return;
        }
    
        try {
            const productsRef = collection(db, 'products');
            const nearbyStoreIds = nearbyStores.map(store => store.id);
    
            // Create individual queries for each searchable field, filtered by nearby stores
            const brandQuery = query(
                productsRef,
                where('storeID', 'in', nearbyStoreIds),
                where('brandName_2', '>=', searchedTerm),
                where('brandName_2', '<=', searchedTerm + '\uf8ff'),
                limit(10)
            );
    
            const productQuery = query(
                productsRef,
                where('storeID', 'in', nearbyStoreIds),
                where('productName_2', '>=', searchedTerm),
                where('productName_2', '<=', searchedTerm + '\uf8ff'),
                limit(10)
            );
    
            const descriptionQuery = query(
                productsRef,
                where('storeID', 'in', nearbyStoreIds),
                where('shortDescription_2', '>=', searchedTerm),
                where('shortDescription_2', '<=', searchedTerm + '\uf8ff'),
                limit(10)
            );
    
            // Execute all queries in parallel
            const [brandSnapshot, productSnapshot, descriptionSnapshot] = await Promise.all([
           
           
                getDocs(brandQuery), // 1
                getDocs(productQuery), // 2
                getDocs(descriptionQuery) // 3
         
         
            ]);
    
            // Merge results and remove duplicates by product ID
            const results = [
                ...brandSnapshot.docs,
                ...productSnapshot.docs,
                ...descriptionSnapshot.docs
            ].reduce((acc, doc) => {
                const data = doc.data();
                if (!acc.find(item => item.id === doc.id)) {
                    acc.push({ id: doc.id, ...data });
                }
                return acc;
            }, []);
    
            setSearchResults(results.length > 0 ? results : [{ id: 'none', brandName: 'No results found', title: "" }]);
        } catch (error) {
            console.error("Error fetching search results:", error);
            setSearchResults([]); // Reset results in case of error
        }
    };
    
    

    return (
        <>
            {isSearchOpen && (
                <div id="searchbar" className="backdrop">
                    <div className="searchbar_content" ref={searchRef}>
                        <div className="search_box">
                            <input
                                type="search"
                                className="input_field"
                                placeholder="Search for product..."
                                onChange={handleSearching}
                            />
                        </div>

                        {searchResults.length > 0 && searchTerm != "" && (
                            <div className="search_results">
                                {searchResults.map(item => (
                                    <Link
  to={item.id === "none" ? "/" : `${item.path}${item.id}`}
  onClick={closeSearch}
  key={item.id}
>
  {/* <span style={{fontWeight: "bold"}}> {item.brandName} {item.title}</span> */}
  <span > {item.brandName} {item.title}</span>
  <span style={{fontWeight: "bold"}}> {item.id !== "none" && ` (at ${item.storeName})`}</span>
 
</Link>

                                 /*   <Link
                                        // to={`${item.path}${item.id}`}

                 to={item.id == "none" ? "/": `${item.path}${item.id}` }

                                        onClick={closeSearch}
                                        key={item.id}
                                    >
                                     {`${item.brandName}
                                         ${item.title}
                                         
                                          ${item.id == "none" ? "": `(at ${item.storeName})`}`} 

                                    </Link>
                                    */
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default SearchBar;



// import React, { useContext, useRef } from 'react';
// import { Link } from 'react-router-dom';
// import commonContext from '../../contexts/common/commonContext';
// import { db } from '../../firebase';
// import { collection, getDocs, query, where, limit } from 'firebase/firestore';
// import useOutsideClose from '../../hooks/useOutsideClose';
// import useScrollDisable from '../../hooks/useScrollDisable';

// const SearchBar = () => {
//     const [latitude, setLatitude] = useState(0);
//     const [longitude, setLongitude] = useState(0);
//     const [userLocation, setUserLocation] = useState(false);
//     const [nearbyStores, setNearbyStores] = useState([]);

//     const { isSearchOpen, toggleSearch, searchResults, setSearchResults } = useContext(commonContext);

//     const searchRef = useRef();

//     useEffect(() => {
//         location_find();
//     }, []);


//     // Close SearchBar
//     const closeSearch = () => {
//         toggleSearch(false);
//         setSearchResults([]);
//     };

//     useOutsideClose(searchRef, closeSearch);
//     useScrollDisable(isSearchOpen);

//       // Function to get user location
//       async function location_find() {
//         if (navigator.geolocation) {
//             navigator.geolocation.getCurrentPosition((position) => {
//                 setLatitude(position.coords.latitude);
//                 setLongitude(position.coords.longitude);
//                 setUserLocation(true);
//                 fetchNearbyStores(position.coords.latitude, position.coords.longitude);
//             }, (error) => {
//                 alert('Unable to retrieve location.');
//             });
//         }
//     }

//     // New fetchNearbyStores function using geohash bounds for querying
//     const fetchNearbyStores = async (lat, long) => {
//         const center = [lat, long]; // User's current location
//         // const radiusInM = 18 * 1000; // 18 km radius
//         const radiusInM = 1500 * 1000; // 15 km radius
        
//         const bounds = geofire.geohashQueryBounds(center, radiusInM);
//         const promises = [];

//         for (const b of bounds) {
//             const q = query(
//                 collection(db, 'stores'),
//                 orderBy('geohash'), 
//                 startAt(b[0]),
//                 endAt(b[1])
//             );
//             promises.push(getDocs(q));
//         }

//         const snapshots = await Promise.all(promises);
//         const matchingDocs = [];

//         for (const snap of snapshots) {
//             for (const doc of snap.docs) {
//                 const storeData = doc.data();
//                 const storeLat = storeData.lat;
//                 const storeLng = storeData.lng;

//                 const distanceInKm = geofire.distanceBetween([storeLat, storeLng], center);
//                 const distanceInM = distanceInKm * 1000;
//                 if (distanceInM <= radiusInM) {
//                     matchingDocs.push({ id: doc.id, ...storeData });
//                 }
//             }
//         }

//         setNearbyStores([...matchingDocs]); // Add "All" as a default option
//     };


//     // Handle Search
//     const handleSearching = async (e) => {
//         // const searchedTerm = e.target.value.trim();
//          const searchedTerm = e.target.value.toLowerCase().trim();

//         if (searchedTerm === '') {
//             setSearchResults([]);
//             return;
//         }

//         try {
//             const productsRef = collection(db, 'products');
//             const q = query(
//                 productsRef,
//                 where('searchkey', '>=', searchedTerm),
//                 where('searchkey', '<=', searchedTerm + '\uf8ff'),
//                 limit(10) // Adjust limit as needed
//             );

//             const querySnapshot = await getDocs(q);
//             const updatedSearchResults = querySnapshot.docs
//                 .map(doc => ({ id: doc.id, ...doc.data() }))
//                 .filter(item => item.title.toLowerCase().includes(searchedTerm));
//                 // .filter(item => item.title.includes(searchedTerm));

//             setSearchResults(updatedSearchResults);
//         } catch (error) {
//             console.error("Error fetching search results:", error);
//             setSearchResults([]); // Reset results in case of error
//         }
//     };

//     return (
//         <>
//             {isSearchOpen && (
//                 <div id="searchbar" className="backdrop">
//                     <div className="searchbar_content" ref={searchRef}>
//                         <div className="search_box">
//                             <input
//                                 type="search"
//                                 className="input_field"
//                                 placeholder="Search for product..."
//                                 onChange={handleSearching}
//                             />
//                         </div>

//                         {searchResults.length > 0 && (
//                             <div className="search_results">
//                                 {searchResults.map(item => (
//                                     <Link
//                                         to={`${item.path}${item.id}`}
//                                         onClick={closeSearch}
//                                         key={item.id}
//                                     >
//                                         {/* {item.title} */}
//                                         {`${item.brandName} ${item.title} in`} 
//                                     </Link>
//                                 ))}
//                             </div>
//                         )}
//                     </div>
//                 </div>
//             )}
//         </>
//     );
// };

// export default SearchBar;



// import React, { useContext, useRef } from 'react';
// import { Link } from 'react-router-dom';
// import commonContext from '../../contexts/common/commonContext';
// import productsData from '../../data/productsData';
// import useOutsideClose from '../../hooks/useOutsideClose';
// import useScrollDisable from '../../hooks/useScrollDisable';
// // import { AiOutlineSearch } from 'react-icons/ai';


// const SearchBar = () => {

//     const { isSearchOpen, toggleSearch, searchResults, setSearchResults } = useContext(commonContext);

//     const searchRef = useRef();

//     // closing the SearchBar
//     const closeSearch = () => {
//         toggleSearch(false);
//         setSearchResults([]);
//     };

//     useOutsideClose(searchRef, closeSearch);

//     useScrollDisable(isSearchOpen);


//     // handling Search
//     const handleSearching = (e) => {
//         const searchedTerm = e.target.value.toLowerCase().trim();

//         const updatedSearchResults = productsData.filter(item => item.title.toLowerCase().includes(searchedTerm));

//         searchedTerm === '' ? setSearchResults([]) : setSearchResults(updatedSearchResults);
//     };


//     return (
//         <>
//             {
//                 isSearchOpen && (
//                     <div id="searchbar" className="backdrop">
//                         <div className="searchbar_content" ref={searchRef}>
//                             <div className="search_box">
//                                 <input
//                                     type="search"
//                                     className="input_field"
//                                     placeholder="Search for product..."
//                                     onChange={handleSearching}
//                                 />
//                                 {/* <button
//                                     type="button"
//                                     className="btn"
//                                     disabled={searchResults.length === 0}
//                                 >
//                                     <AiOutlineSearch />
//                                 </button> */}
//                             </div>

//                             {
//                                 searchResults.length !== 0 && (
//                                     <div className="search_results">
//                                         {
//                                             searchResults.map(item => {
//                                                 const { id, title, path } = item;
//                                                 return (
//                                                     <Link
//                                                         to={`${path}${id}`}
//                                                         onClick={closeSearch}
//                                                         key={id}
//                                                     >
//                                                         {title}
//                                                     </Link>
//                                                 );
//                                             })
//                                         }
//                                     </div>
//                                 )
//                             }
//                         </div>
//                     </div>
//                 )
//             }
//         </>
//     );
// };

// export default SearchBar;