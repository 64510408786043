import React, { useContext, useState, useEffect } from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa';
import cartContext from '../../contexts/cart/cartContext';


const QuantityBox = (props) => {

    const { itemId, itemQuantity, quantity } = props;
    const [quantity_count, handleQuantity_count] = useState(1);


    const { incrementItem, decrementItem } = useContext(cartContext);

    useEffect(() => {
        handleQuantity_count(itemQuantity)     
    }, [])
    

    return (
        <>
            <div className="quantity_box">
                <button
                    type="button"
                    onClick={() => { 
                        if ((quantity_count-1) <= quantity) {
                        decrementItem(itemId); handleQuantity_count(quantity_count-1); 
                    }}}
                    
                >
                    <FaMinus />
                </button>
                <input style={{width: 100}}
                type='number'
                className="quantity_count"
                value={quantity_count}
                onChange={(e)=>{
                    const quantity_count_ = e.target.value

                    if (quantity_count_ <= quantity) {
                        
                  

                    // handleQuantity_count(quantity_count_)
                    // if (Number(quantity_count_) == 0 || quantity_count_ == "" ) {
                    //     handleQuantity_count(1) 
                    // }
                    // console.log(lo);
                    
                    // itemQuantity = searchedTerm;
                    if (quantity_count_ > quantity_count) {
                        let lo = quantity_count_ - quantity_count
                        let lop = 0
                        while (lop < lo) {
                            incrementItem(itemId);
                            lop++;
                        };

                        
                        handleQuantity_count(Number(quantity_count_))

                    } else if (quantity_count_ < quantity_count) {
                        let lo = quantity_count - quantity_count_ 
                        let lop = 0
                        while (lop < lo) {
                            decrementItem(itemId);
                            lop++;
                        };

                        handleQuantity_count(Number(quantity_count_))
                    }

                }

                }}
                />
                {/* <span className="quantity_count">
                    {itemQuantity}
                </span> */}
                <button
                    type="button"
                    onClick={() => {
                        if ((quantity_count+1) <= quantity) {
                        incrementItem(itemId); handleQuantity_count(quantity_count+1);
                        }
                    } }
                    // disabled={itemQuantity >= 5}
                >
                    <FaPlus />
                </button>
            </div>
        </>
    );
};

export default QuantityBox;