import React, { useState, useEffect } from 'react';
import ProductCard from './ProductCard';
import { collection, query, where, getDocs, orderBy, limit, startAfter } from 'firebase/firestore';
import { db } from '../../firebase.js';
import { Link } from 'react-router-dom';
import { BsArrowRight } from 'react-icons/bs';
import useActive from '../../hooks/useActive';

const TopProducts = ({ nearbyStores, selectedStore }) => {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [lastVisible, setLastVisible] = useState(null); // Keeps track of the last document per store in "All" mode
    const [hasMore, setHasMore] = useState(true);
    const batchSize = 20;

    useEffect(() => {
        if (selectedStore === "All") {
            fetchInitialProductsForNearbyStores(nearbyStores);
        } else if (selectedStore) {
            fetchInitialProductsForStore(selectedStore);
        }
    }, [selectedStore, nearbyStores]);

    const fetchInitialProductsForStore = async (storeId) => {
        setLoading(true);
        const productsCollection = collection(db, "products");
        const productQuery = query(
            productsCollection,
            where("storeID", "==", storeId),
                  // orderBy("createdAt"),
                  orderBy("salesPower", "desc"),
            limit(batchSize)
        );

        const productSnapshot = await getDocs(productQuery);
        const fetchedProducts = productSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        setProducts(fetchedProducts);
        if (productSnapshot.docs.length > 0) {
            setLastVisible({ [storeId]: productSnapshot.docs[productSnapshot.docs.length - 1] });
            setHasMore(productSnapshot.docs.length === batchSize);
        } else {
            setHasMore(false);
        }
        setLoading(false);
    };

    const fetchInitialProductsForNearbyStores = async (stores) => {
        setLoading(true);
        let allFetchedProducts = [];
        let lastVisibleByStore = {};

        for (const store of stores.filter(store => store.id !== "All")) {
            const productsCollection = collection(db, "products");
            const productQuery = query(
                productsCollection,
                where("storeID", "==", store.id),
                      // orderBy("createdAt"),
                      orderBy("salesPower", "desc"),
                limit(batchSize)
            );
            const productSnapshot = await getDocs(productQuery);
            const fetchedProducts = productSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

            allFetchedProducts = [...allFetchedProducts, ...fetchedProducts];
            if (productSnapshot.docs.length > 0) {
                lastVisibleByStore[store.id] = productSnapshot.docs[productSnapshot.docs.length - 1];
            }
        }

        setProducts(allFetchedProducts);
        setLastVisible(lastVisibleByStore);
        setHasMore(Object.keys(lastVisibleByStore).length > 0);
        setLoading(false);
    };

    const loadMoreProducts = async () => {
        if (!lastVisible) return;

        setLoading(true);
        let newProducts = [];
        let updatedLastVisible = { ...lastVisible };

        if (selectedStore === "All") {
            for (const storeId in lastVisible) {
                const productsCollection = collection(db, "products");
                const productQuery = query(
                    productsCollection,
                    where("storeID", "==", storeId),
                    // orderBy("createdAt"),
                    orderBy("salesPower", "desc"),
                    startAfter(lastVisible[storeId]),
                    limit(batchSize)
                );
                const productSnapshot = await getDocs(productQuery);
                const storeProducts = productSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                newProducts = [...newProducts, ...storeProducts];

                if (productSnapshot.docs.length > 0) {
                    updatedLastVisible[storeId] = productSnapshot.docs[productSnapshot.docs.length - 1];
                } else {
                    delete updatedLastVisible[storeId]; // Remove store if no more products to load
                }
            }
        } else {
            const productsCollection = collection(db, "products");
            const productQuery = query(
                productsCollection,
                where("storeID", "==", selectedStore),
                      // orderBy("createdAt"),
                      orderBy("salesPower", "desc"),
                startAfter(lastVisible[selectedStore]),
                limit(batchSize)
            );
            const productSnapshot = await getDocs(productQuery);
            newProducts = productSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            
            if (productSnapshot.docs.length > 0) {
                updatedLastVisible[selectedStore] = productSnapshot.docs[productSnapshot.docs.length - 1];
            } else {
                setHasMore(false); // No more products to load for this store
            }
        }

        setProducts(prevProducts => [...prevProducts, ...newProducts]);
        setLastVisible(updatedLastVisible);
        setHasMore(Object.keys(updatedLastVisible).length > 0);
        setLoading(false);
    };

    return (
        <>
        {/* { loading ? (
                            <>
                            <h3 style={{textAlign: 'center'}}>Loading...</h3>
                           
                            <div className="separator"></div>
                            </>
                        ) : (
                            <>
            
                            </>
                        ) 
            
                        } */}
        <div className="wrapper products_wrapper">
            {products.map(item => (
                <ProductCard key={item.id} {...item} />
            ))}

<div className="card products_card browse_card" style={{ cursor: "pointer"}}  onClick={loadMoreProducts}>
               
                    {loading ? (<>
               Loading... <br/> <img  src="./Animation-l.gif" />
              
               </>) : (<>
              Load More <br /> <BsArrowRight />
               </>)  }
                     
                     
             
            </div>

        </div>
        </>
    );
};

export default TopProducts;
