// import { initializeApp } from "firebase/app";
// import { getFirestore } from "firebase/firestore";
// import { getStorage } from "firebase/storage";
// import { getAuth, signInWithPopup, GoogleAuthProvider, signInWithEmailAndPassword  } from 'firebase/auth';

// import { GeoFirestore } from 'geofirestore';

// const firebaseConfig = {
//     // your Firebase config here
//     apiKey: "AIzaSyBvEeweHv6fee4qWwrg1ASsAsfYfEoMXZE",
//     authDomain: "cartpost-web.firebaseapp.com",
//     databaseURL: "https://cartpost-web-default-rtdb.firebaseio.com",
//     projectId: "cartpost-web",
//     storageBucket: "cartpost-web.appspot.com",
//     messagingSenderId: "936600138275",
//     appId: "1:936600138275:web:d1c09731741adb7f12b796",
//     measurementId: "G-S7RE9RN74G"
// };
// const app = initializeApp(firebaseConfig);

// const db = getFirestore(app);
// const storage = getStorage(app);
// const auth = getAuth(app);

// // const firebaseApp = initializeApp(firebaseConfig);
// const firestore = db;

// // Initialize GeoFirestore
// const geoFirestore = new GeoFirestore(firestore);



// const googleProvider = new GoogleAuthProvider();

// const signInWithGoogle = () => {
//   return signInWithPopup(auth, googleProvider);
// };


// export { app, db, storage, auth, signInWithGoogle, geoFirestore, firestore };

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

import { getStorage } from "firebase/storage";
import { getAuth, signInWithPopup, GoogleAuthProvider, signInWithEmailAndPassword  } from 'firebase/auth';


const firebaseConfig = {
  // ...
  apiKey: "AIzaSyBvEeweHv6fee4qWwrg1ASsAsfYfEoMXZE",
  authDomain: "cartpost-web.firebaseapp.com",
  databaseURL: "https://cartpost-web-default-rtdb.firebaseio.com",
  projectId: "cartpost-web",
  storageBucket: "cartpost-web.appspot.com",
  messagingSenderId: "936600138275",
  appId: "1:936600138275:web:d1c09731741adb7f12b796",
  measurementId: "G-S7RE9RN74G"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);


const googleProvider = new GoogleAuthProvider();

const signInWithGoogle = () => {
  return signInWithPopup(auth, googleProvider);
};


export { app, db, storage, auth, signInWithGoogle };
